import type { Supplier } from "@/services/supplier";
import type { ProductsQueryParams } from "./types";
import type { SearchProductsPayload } from "@/services/search";
import type { Account } from "@/services/account";
import { defaultPageSize } from "./constants";

export const productsQueryToPayload = (
  query: ProductsQueryParams,
  supplier: Supplier,
  account?: Account | null,
  page = 0,
): SearchProductsPayload => {
  const queryCatgories = query.categories.split(",").filter(Boolean);
  const clientCategories = account?.categoryIds ?? [];

  return {
    name: query.q ?? "",
    supplierId: supplier.id,
    warehouseId: supplier.catalog.warehouseId,
    categoryId: queryCatgories.length > 0 ? queryCatgories : clientCategories,
    brandId: query.brands.split(",").filter(Boolean),
    hasStock: query.stock === "all" ? null : query.stock === "yes",
    hasOffer: query.offer === "all" ? null : query.offer === "yes",
    segmentId: account?.suppliers.find((s) => s.supplierId === supplier.id)?.segments[0]?.id,
    status: true,
    type: supplier.catalog.productTypes,
    page,
    size: defaultPageSize,
    sort: {
      field: query.sort.split(",")[0],
      sort: query.sort.split(",")[1],
    },
  };
};
