import { useMemo } from "react";
import { getSearchProducts, type Aggregations, type ProductFilterValues } from "@/services/search";
import { useCookieState, useInfiniteScroll } from "ahooks";
import type { Supplier } from "@/services/supplier";
import { useTranslations } from "@/i18n";
import type { Lang } from "@/types";
import { type Choice } from "@/components/controls/SelectMultiple";
import { addProductToCart } from "@/features/cart/cart.store";
import { useQueryParams } from "./use-query-params";
import type { InfiniteData, ProductsQueryParams } from "./types";
import { productsQueryToPayload } from "./query-to-payload";
import { type ProductCardProps } from "./ui/ProductCards";
import { useAccount } from "@/hooks/use-account";
import { openQuickView } from "../quick-view/quick-view.store";
import { searchProductToCatalogProduct } from "../mappers";
import { productsViewModeCookieName } from "./constants";
import { ProductsUI } from "./ui/ProductsUI";
import { navigate } from "astro:transitions/client";
import { useQuote } from "@/hooks/use-quote";

type Props = {
  lang: Lang;
  supplier: Supplier;
  initialViewMode: string;
  initialData?: InfiniteData;
  initialQueryParams: ProductsQueryParams;
  filterValues: ProductFilterValues;
};

export const Products = ({
  lang,
  supplier,
  initialData,
  initialViewMode,
  initialQueryParams,
  filterValues,
}: Props) => {
  const { account, ready } = useAccount();
  const { isQuote } = useQuote(supplier);

  const whiteListedClientFilters = !ready
    ? {
        categories: [],
        brands: filterValues.brands,
      }
    : account
      ? {
          categories: account.categoryIds?.length
            ? filterValues.categories.filter((c) => account.categoryIds?.includes(c.value))
            : filterValues.categories,
          brands: filterValues.brands,
        }
      : filterValues;

  const [viewMode = initialViewMode, setViewMode] = useCookieState(productsViewModeCookieName);

  const {
    query,
    selectedBrands,
    setSelectedBrands,
    selectedCategories,
    setSelectedCategories,
    selectedStock,
    setSelectedStock,
    selectedOffer,
    setSelectedOffer,
    selectedSort,
    setSelectedSort,
  } = useQueryParams(initialQueryParams);

  const {
    data: dataWithPossibleDuplicates = initialData,
    loading,
    loadingMore,
    loadMore,
    mutate,
  } = useInfiniteScroll<InfiniteData>(
    async (data) => {
      const searchPayload = productsQueryToPayload(query, supplier, account, data?.nextPage);
      return getSearchProducts(searchPayload).then((result) => ({
        list: (result.content ?? []).map((product) => {
          const searchProduct = searchProductToCatalogProduct(product, supplier, lang);
          return {
            ...searchProduct,
            addable: isQuote || searchProduct.addable,
            totalStock: isQuote ? Infinity : searchProduct.totalStock,
          };
        }),
        nextPage: result.totalPages > result.page + 1 ? result.page + 1 : undefined,
        aggregations: result.aggregations,
      }));
    },
    {
      manual: !initialData && !account,
      reloadDeps: [query, account?.id, isQuote],
      onError(error) {
        console.error(error);
      },
    },
  );

  // Remove list items duplicates by id
  const data: InfiniteData | undefined = dataWithPossibleDuplicates
    ? {
        ...dataWithPossibleDuplicates,
        list: dataWithPossibleDuplicates.list.filter(
          (product, index, self) => self.findIndex((p) => p.id === product.id) === index,
        ),
      }
    : undefined;

  const filterChoices = useFilterChoices(whiteListedClientFilters, data?.aggregations);

  const productCardsData: ProductCardProps[] =
    data?.list.map((product, index) => ({
      product,
      setProduct: (product) => {
        mutate({ ...data, list: data.list.with(index, product) });
      },
      onPrimaryAction: () => {
        if (product.type === "GROUP") {
          navigate(`${lang}/products/${product.id}`);
          return;
        }

        addProductToCart(
          {
            product,
            quantity: 1,
            unitType: product.initialUnitType,
          },
          { open: true },
        );
      },
      onSecondaryAction: () => {
        if (product.type === "GROUP") {
          navigate(`${lang}/products/${product.id}`);
          return;
        }

        openQuickView(product);
      },
    })) ?? [];

  return (
    <ProductsUI
      lang={lang}
      supplier={supplier}
      productCards={productCardsData}
      brandChoices={filterChoices.brands ?? []}
      categoryChoices={filterChoices.categories ?? []}
      stockChoices={getFilterByStockItems({ lang })}
      offerChoices={getChoicesForOfferFilter({ lang })}
      sortChoices={getSortItems({ lang })}
      selectedBrands={selectedBrands}
      setSelectedBrands={setSelectedBrands}
      selectedCategories={selectedCategories}
      setSelectedCategories={setSelectedCategories}
      selectedStock={selectedStock}
      setSelectedStock={setSelectedStock}
      selectedOffer={selectedOffer}
      setSelectedOffer={setSelectedOffer}
      selectedSort={selectedSort}
      setSelectedSort={setSelectedSort}
      query={query}
      filtersMode={supplier.catalogFiltersMode}
      viewMode={viewMode as "list" | "grid"}
      setViewMode={setViewMode}
      initialLoading={loading && !data?.list.length}
      showLoadMore={!!data && data.list.length > 0 && data.nextPage !== undefined}
      loadingMore={loadingMore}
      loadMore={loadMore}
    />
  );
};

const useFilterChoices = (filterValues: ProductFilterValues, aggregations?: Aggregations) => {
  const filterChoices = useMemo(() => {
    const brandQties = aggregations?.brand.reduce(
      (record, item) => {
        record[item.id] = item.quantity;
        return record;
      },
      {} as Record<string, number>,
    );

    const brands: Choice[] =
      filterValues?.brands
        .filter((b) => b.value)
        .map((a) => ({
          label: a.name,
          renderLabel: (
            <div className="flex justify-between">
              <span>{a.name}</span>
              <span>{brandQties?.[a.value]}</span>
            </div>
          ),
          value: a.value,
        })) ?? [];

    const categoryQties = aggregations?.category.reduce(
      (record, item) => {
        record[item.id] = item.quantity;
        return record;
      },
      {} as Record<string, number>,
    );

    const categories: Choice[] =
      filterValues?.categories
        .filter((b) => b.value)
        .map((a) => ({
          label: a.name,
          renderLabel: (
            <div className="flex justify-between">
              <span>{a.name}</span>
              <span>{categoryQties?.[a.value]}</span>
            </div>
          ),
          value: a.value,
        })) ?? [];

    return { brands, categories };
  }, [filterValues, aggregations]);

  return filterChoices;
};

const getFilterByStockItems = ({ lang }: { lang: Lang }) => {
  const t = useTranslations(lang);
  const items = [{ value: "all" }, { value: "yes" }, { value: "no" }] as const;

  return items.map((item) => ({
    ...item,
    label: t(`products.filters.stock.${item.value}`),
  }));
};

const getChoicesForOfferFilter = ({ lang }: { lang: Lang }) => {
  const t = useTranslations(lang);
  const items = [{ value: "all" }, { value: "yes" }, { value: "no" }] as const;

  return items.map((item) => ({
    ...item,
    label: t(`products.filters.offer.${item.value}`),
  }));
};

const getSortItems = ({ lang }: { lang: Lang }) => {
  const t = useTranslations(lang);

  const items = [
    {
      value: "featured,desc",
      disabled: false,
    },
    {
      value: "name,asc",
      disabled: false,
    },
    {
      value: "name,desc",
      disabled: false,
    },
    {
      value: "date,desc",
      disabled: true,
    },
    {
      value: "date,asc",
      disabled: true,
    },
    {
      value: "price,desc",
      disabled: true,
    },
    {
      value: "price,asc",
      disabled: true,
    },
  ] as const;

  return items.map((item) => ({
    ...item,
    label: t(`products.sort.${item.value}`),
  }));
};
